module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://flatrender.tora.reviews","noTrailingSlash":true,"exclude":["https://flatrender.tora.reviews/company/careers"]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2000,"backgroundColor":"transparent","showCaptions":true,"linkImagesToOriginal":false,"withWebp":true,"quality":80},
    },{
      plugin: require('../plugins/gatsby-remark-copy-code-button/gatsby-browser.js'),
      options: {"plugins":[],"buttonContainerClass":"copy-code-button-container","buttonClass":"copy-code-button","svgIcon":"</>","tooltipText":"Copy to clipboard!","toasterClass":"copy-code-button-overlay","toasterTextClass":"copy-code-button-overlay-text","toasterText":"Text copied to clipboard!"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","theme_color":"#0f1e57","icon":"src/volterra-logo.png","cache_busting_mode":"none","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TB2766F","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{}},"routeChangeEventName":"flatrender-local-navigation"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
